import * as React from 'react'
import type { HeadFC } from 'gatsby'
import Contact from '../components/Contact'
import MainLayout from '../Layouts/MainLayout'
import { SEO } from '../components/seo'
import Container from '../components/Container'
import * as style from '../scss/blog_index.module.scss'

const BlogIndexPage: React.FC = () => {
  return (
    <MainLayout>
      <main className={style.page}>
        <div className={style.hero}>
          <Container>
            <h1>Neuro Blog&apos;s</h1>
          </Container>
        </div>
        <Container>
          <div className={style.error}>There is no article yet</div>
        </Container>
      </main>
      <Contact />
    </MainLayout>
  )
}

export default BlogIndexPage

export const Head: HeadFC = () => {
  return <SEO title='Our team' />
}
